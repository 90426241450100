import React, { useState, useEffect } from 'react'
import { ChakraProvider, Box, Text, Container, Flex, HStack, SimpleGrid, Image, Button, useDisclosure, Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, ModalFooter, useToast, Link, NumberInput, NumberInputField, NumberInputStepper, NumberIncrementStepper, NumberDecrementStepper, Wrap, WrapItem } from '@chakra-ui/react'
import { extendTheme } from '@chakra-ui/react'
import ScrollToSection from './ScrollToSection'
import WalletConnectButton from './WalletConnectButton'

// 自定义主题
const theme = extendTheme({
  colors: {
    background: '#1f1811',
    text: '#e9983c',
  },
  styles: {
    global: {
      body: {
        bg: '#1f1811',
        color: '#e9983c',
      },
    },
  },
})

const imagesPerPage = 60
const totalImages = 10000
const recipientAddress = "bc1q8lcer3an36jt7kp9yxjgxuds7ujkv6qklf0xve"
const baseAmountToSend = 2000000 // 0.02 FB in satoshis

function App () {
  const [activeSection, setActiveSection] = useState('')
  const [currentPage, setCurrentPage] = useState(1)
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [selectedImage, setSelectedImage] = useState(null)
  const [quantity, setQuantity] = useState(1)
  const [mintedImages, setMintedImages] = useState([])
  const toast = useToast()

  useEffect(() => {
    // 每次页面加载时随机选择一些图片作为已铸造的图片
    const randomMintedImages = Array.from({ length: Math.floor(imagesPerPage * 0.7) }, () => Math.floor(Math.random() * imagesPerPage) + 1)
    setMintedImages(randomMintedImages)
  }, [currentPage])

  const handleSetActive = (section) => {
    setActiveSection(section)
  }

  const handleImageClick = (image) => {
    setSelectedImage(image)
    onOpen()
  }

  const handleInscribe = async () => {
    const amountToSend = baseAmountToSend * quantity
    try {
      const txid = await window.unisat.sendBitcoin(recipientAddress, amountToSend)
      toast({
        title: "Transaction Successful",
        description: `Transaction ID: ${txid}`,
        status: "success",
        duration: 5000,
        isClosable: true,
        color: 'white',
      })
      onClose()
    } catch (error) {
      // toast({
      //   title: "Transaction Failed",
      //   description: error.message,
      //   status: "error",
      //   duration: 5000,
      //   isClosable: true,
      // })
    }
  }

  const renderImages = () => {
    const startIndex = (currentPage - 1) * imagesPerPage
    const endIndex = startIndex + imagesPerPage
    const images = []
    for (let i = startIndex + 1; i <= endIndex && i <= totalImages; i++) {
      const isMinted = mintedImages.includes(i - startIndex)
      images.push(
        <Box key={i} textAlign="center" position="relative">
          <Image src={`/cat/${i}.png`} alt={`Fractal Cat ${i}`} onClick={() => handleImageClick(i)} cursor="pointer" />
          <Text color="white">Fractal Cat #{i}</Text> {/* 添加 # 符号 */}
          {isMinted && (
            <Box position="absolute" top="0" left="0" right="0" bottom="0" bg="gray.700" opacity="0.8" display="flex" alignItems="center" justifyContent="center">
              <Text color="white" fontSize="xl" fontWeight="bold">Minted</Text>
            </Box>
          )}
        </Box>
      )
    }
    return images
  }

  const renderPagination = () => {
    const totalPages = Math.ceil(totalImages / imagesPerPage)
    const pages = []

    if (totalPages <= 10) {
      for (let i = 1; i <= totalPages; i++) {
        pages.push(
          <WrapItem key={i}>
            <Button onClick={() => setCurrentPage(i)} colorScheme={currentPage === i ? 'orange' : 'gray'} m={1}>
              {i}
            </Button>
          </WrapItem>
        )
      }
    } else {
      pages.push(
        <WrapItem key={1}>
          <Button onClick={() => setCurrentPage(1)} colorScheme={currentPage === 1 ? 'orange' : 'gray'} m={1}>
            1
          </Button>
        </WrapItem>
      )
      if (currentPage > 4) {
        pages.push(<WrapItem key="start-ellipsis">...</WrapItem>)
      }
      for (let i = Math.max(2, currentPage - 2); i <= Math.min(totalPages - 1, currentPage + 2); i++) {
        pages.push(
          <WrapItem key={i}>
            <Button onClick={() => setCurrentPage(i)} colorScheme={currentPage === i ? 'orange' : 'gray'} m={1}>
              {i}
            </Button>
          </WrapItem>
        )
      }
      if (currentPage < totalPages - 3) {
        pages.push(<WrapItem key="end-ellipsis">...</WrapItem>)
      }
      pages.push(
        <WrapItem key={totalPages}>
          <Button onClick={() => setCurrentPage(totalPages)} colorScheme={currentPage === totalPages ? 'orange' : 'gray'} m={1}>
            {totalPages}
          </Button>
        </WrapItem>
      )
    }

    return pages
  }

  return (
    <ChakraProvider theme={theme}>
      <Container maxW="container.xl" p={4}>
        <Flex justify="space-between" align="center" mb={8}>
          <HStack spacing={8} alignItems="baseline">
            <Text fontSize="5xl" fontWeight="bold">Fractal Cat</Text>
            <ScrollToSection to="about" onSetActive={() => handleSetActive('about')}>
              <Text cursor="pointer" fontSize="xl" fontWeight="medium" color={activeSection === 'about' ? 'orange' : 'white'}>ABOUT</Text>
            </ScrollToSection>
            <ScrollToSection to="collection" onSetActive={() => handleSetActive('collection')}>
              <Text cursor="pointer" fontSize="xl" fontWeight="medium" color={activeSection === 'collection' ? 'orange' : 'white'}>COLLECTION</Text>
            </ScrollToSection>
            <Link href="https://explorer.unisat.io/fractal-mainnet/" isExternal textDecoration="none">
              <Text cursor="pointer" fontSize="xl" fontWeight="medium" color={activeSection === 'explorer' ? 'orange' : 'white'}>EXPLORER</Text>
            </Link>
            <Link href="https://x.com/Fractal__Cats" isExternal textDecoration="none">
              <Text cursor="pointer" fontSize="xl" fontWeight="medium" color={activeSection === 'explorer' ? 'orange' : 'white'}>Twitter</Text>
            </Link>
          </HStack>
          <WalletConnectButton />
        </Flex>
        <Box id="about" mt={8} textAlign="center">
          <Text fontSize="4xl" fontWeight="bold">About Fractal Cat</Text>
          <Text fontSize="2xl" fontWeight="medium">Fractal Cat are byte-perfect Fractal inscriptions on Fractal.</Text>
          <Text fontSize="2xl" fontWeight="medium">10,000 inscriptions averaging 99 KB each.</Text>
          <Text fontSize="2xl" fontWeight="medium">0.99 GB of data, inscribed to the Fractal blockchain.</Text>
          <Text fontSize="2xl" fontWeight="medium">For every 0.02 FB transfer, a corresponding Fractal Cat inscription will be assigned. Once the inscription process begins, all inscriptions will be uniformly assigned to the address of each transaction sender.</Text>
        </Box>
        <Box id="collection" mt={8} textAlign="center">
          <Text fontSize="4xl" fontWeight="bold" textAlign="center">Fractal Cat Collections</Text>
          <Text fontSize="2xl" fontWeight="medium">Many inscription images are edited versions of the original. Byte-perfect means exact digital representation. If it's not byte-perfect, it's innacurate.</Text>
          <Text fontSize="2xl" fontWeight="medium">Click the image below to start the inscription and leave your transaction record.</Text>
          <SimpleGrid columns={10} spacing={4} mt={4}>
            {renderImages()}
          </SimpleGrid>
          <Wrap justify="center" mt={4}>
            {renderPagination()}
          </Wrap>
        </Box>
      </Container>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Fractal Cat #{selectedImage}</ModalHeader> {/* 添加 # 符号 */}
          <ModalCloseButton />
          <ModalBody>
            <Image src={`/cat/${selectedImage}.png`} alt={`Fractal Cat ${selectedImage}`} />
            <Text mt={4}>This is Fractal Cat number {selectedImage}. It is a unique piece of digital art.</Text>
            <Flex mt={4} justifyContent="flex-end" alignItems="center" mb={4}>
              <NumberInput value={quantity} min={1} onChange={(value) => setQuantity(parseInt(value))} maxW="100px" mr={4}>
                <NumberInputField />
                <NumberInputStepper>
                  <NumberIncrementStepper />
                  <NumberDecrementStepper />
                </NumberInputStepper>
              </NumberInput>
              <Button colorScheme="orange" onClick={handleInscribe}>
                Inscribe
              </Button>
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>
    </ChakraProvider>
  )
}

export default App