import React, { useState, useEffect, useCallback } from 'react';
import { Button, useToast, Box, Alert, AlertTitle, AlertDescription } from '@chakra-ui/react';
import { motion } from 'framer-motion';
import { ChainType } from './const';

const MotionButton = motion(Button);

const WalletConnectButton = () => {
  const [account, setAccount] = useState(localStorage.getItem('account'));
  const [connected, setConnected] = useState(!!localStorage.getItem('account'));
  const toast = useToast();

  const handleAccountsChanged = useCallback((accounts) => {
    if (accounts.length > 0) {
      setAccount(accounts[0]);
      setConnected(true);
      localStorage.setItem('account', accounts[0]);
    } else {
      setAccount(null);
      setConnected(false);
      localStorage.removeItem('account');
    }
  }, []);

  const handleNetworkChanged = useCallback((network) => {
    console.log("network changed", network);
  }, []);

  const handleChainChanged = useCallback((chain) => {
    console.log("chain changed", chain);
    if (chain.enum !== ChainType.FRACTAL_BITCOIN_MAINNET) {
      toast({
        title: "Network Error",
        description: "Please switch to Fractal Bitcoin Mainnet network.",
        status: "error",
        duration: 5000,
        isClosable: true,
        render: () => (
          <Alert status="error" variant="solid" bg="red.500" color="white">
            <Box flex="1">
              <AlertTitle fontSize="md">Network Error</AlertTitle>
              <AlertDescription fontSize="sm">
                Please switch to Fractal Bitcoin Mainnet network.
              </AlertDescription>
            </Box>
          </Alert>
        ),
      });
    }
  }, [toast]);

  useEffect(() => {
    async function checkUnisat() {
      let unisat = window.unisat;

      for (let i = 1; i < 10 && !unisat; i += 1) {
        await new Promise((resolve) => setTimeout(resolve, 100 * i));
        unisat = window.unisat;
      }

      if (unisat) {
        unisat.on("accountsChanged", handleAccountsChanged);
        unisat.on("networkChanged", handleNetworkChanged);
        unisat.on("chainChanged", handleChainChanged);

        return () => {
          unisat.removeListener("accountsChanged", handleAccountsChanged);
          unisat.removeListener("networkChanged", handleNetworkChanged);
          unisat.removeListener("chainChanged", handleChainChanged);
        };
      }
    }

    checkUnisat();
  }, [handleAccountsChanged, handleNetworkChanged, handleChainChanged]);

  const handleConnect = async () => {
    if (window.unisat) {
      try {
        const chain = await window.unisat.getChain();
        if (chain.enum !== ChainType.FRACTAL_BITCOIN_MAINNET) {
          toast({
            title: "Network Error",
            description: "Please switch to Fractal Bitcoin Mainnet network.",
            status: "error",
            duration: 5000,
            isClosable: true,
            render: () => (
              <Alert status="error" variant="solid" bg="red.500" color="white">
                <Box flex="1">
                  <AlertTitle fontSize="md">Network Error</AlertTitle>
                  <AlertDescription fontSize="sm">
                    Please switch to Fractal Bitcoin Mainnet network.
                  </AlertDescription>
                </Box>
              </Alert>
            ),
          });
          return;
        }

        const accounts = await window.unisat.requestAccounts();
        handleAccountsChanged(accounts);
      } catch (error) {
        console.error("Error connecting to wallet:", error);
        toast({
          title: "Connection Error",
          description: error.message,
          status: "error",
          duration: 5000,
          isClosable: true,
          render: () => (
            <Alert status="error" variant="solid" bg="red.500" color="white">
              <Box flex="1">
                <AlertTitle fontSize="md">Connection Error</AlertTitle>
                <AlertDescription fontSize="sm">
                  {error.message}
                </AlertDescription>
              </Box>
            </Alert>
          ),
        });
      }
    } else {
      toast({
        title: "Unisat Wallet Not Installed",
        description: "Please install Unisat Wallet to use this feature.",
        status: "error",
        duration: 5000,
        isClosable: true,
        render: () => (
          <Alert status="error" variant="solid" bg="red.500" color="white">
            <Box flex="1">
              <AlertTitle fontSize="md">Unisat Wallet Not Installed</AlertTitle>
              <AlertDescription fontSize="sm">
                Please install Unisat Wallet to use this feature.
              </AlertDescription>
            </Box>
          </Alert>
        ),
      });
    }
  };

  const truncateAddress = (address) => {
    return `${address.slice(0, 6)}...${address.slice(-4)}`;
  };

  return (
    <Box>
      <MotionButton
        onClick={handleConnect}
        colorScheme="orange"
        whileHover={{ scale: 1.1 }}
        whileTap={{ scale: 0.9 }}
      >
        {connected ? truncateAddress(account) : 'Connect Wallet'}
      </MotionButton>
    </Box>
  );
};

export default WalletConnectButton;